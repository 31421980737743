import { Component, OnInit } from '@angular/core';
import { SiteProxyService } from '../../proxies/site.proxy.service';
import { Site } from '../../models/Site';
import { ModalService } from '../../../../public/card-software-library/src/lib/_services/modal.service';
import { SiteDetailsModalComponent } from '../_modals/site-details-modal/site-details-modal.component';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrl: './sites.component.scss',
})
export class SitesComponent implements OnInit {
  constructor(
    private readonly siteProxyService: SiteProxyService,
    private readonly modalService: ModalService,
  ) {}
  sites: Site[] = [];
  isLoading = 0;
  ngOnInit() {
    this.isLoading++;
    this.siteProxyService.getSites().subscribe({
      next: (sites) => {
        this.sites = sites;
        this.isLoading--;
      },
      error: (error) => {
        console.error(error);
        this.isLoading--;
      },
    });
  }

  openSiteDetailsModal(site?: Site) {
    this.modalService
      .open(
        SiteDetailsModalComponent,
        {},
        {
          site: site ? site : null,
        },
      )
      .subscribe();
  }
}
