import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseModalComponent } from '../../../../../public/card-software-library/src/lib/_components/base-modal/base-modal.component';
import { Site } from '../../../models/Site';
import { SiteProxyService } from '../../../proxies/site.proxy.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-site-details-modal',
  templateUrl: './site-details-modal.component.html',
  styleUrl: './site-details-modal.component.scss',
})
export class SiteDetailsModalComponent extends BaseModalComponent<Site | null> implements OnInit {
  @Input() site: Site | null = null;

  constructor(
    injector: Injector,
    private readonly siteProxyService: SiteProxyService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.fromGroup.patchValue({
      code: this.site?.code || '',
      name: this.site?.name || '',
      description: this.site?.description || '',
      isActive: this.site?.isActive || true,
      externalSite: this.site?.externalSite || false,
      countryId: this.site?.countryId || '',
    });
  }

  code: FormControl<string | null> = new FormControl('', [Validators.required]);
  name: FormControl<string | null> = new FormControl('', [Validators.required]);
  description: FormControl<string | null> = new FormControl('', [Validators.required]);
  isActive: FormControl<boolean> = new FormControl(true, { nonNullable: true });
  externalSite: FormControl<boolean> = new FormControl(false, {
    nonNullable: true,
  });
  countryId: FormControl<string | null> = new FormControl('', [Validators.required]);

  fromGroup: FormGroup = new FormGroup({
    code: this.code,
    name: this.name,
    description: this.description,
    isActive: this.isActive,
    externalSite: this.externalSite,
    countryId: this.countryId,
  });

  onClose() {
    this.code.value;
    this.fromGroup.controls['code'].value;
    this.close({ action: 'close', data: null });
  }

  onSave() {
    if (this.fromGroup.valid) {
      const site: Site = {
        id: this.site?.id ?? '',
        code: this.code.value ?? '',
        name: this.name.value ?? '',
        description: this.description.value ?? '',
        isActive: this.isActive.value,
        externalSite: this.externalSite.value,
        countryId: this.countryId.value ?? '',
      };

      this.siteProxyService.upsertSite(site).subscribe((site) => {
        this.close({ action: 'save', data: site });
      });

      window.location.reload();
    }
  }
}
