import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AssetModel } from '../models/AssetModel';
import { AssetModelProperty } from '../models/AssetModelProperty';

@Injectable({
  providedIn: 'root',
})
export class ModelPropertyProxyService {
  private readonly baseUrl = environment.proxyUrls.assetApi + '/api/model-property';
  constructor(private readonly httpClient: HttpClient) {}

  getModels(): Observable<AssetModelProperty[]> {
    return this.httpClient.get<AssetModelProperty[]>(`${this.baseUrl}`);
  }

  upsertModel(model: AssetModelProperty): Observable<AssetModelProperty> {
    return this.httpClient.post<AssetModelProperty>(`${this.baseUrl}`, model);
  }

  getMultipleModelProperties(ids: string[]): Observable<AssetModelProperty[]> {
    return this.httpClient.post<AssetModelProperty[]>(`${this.baseUrl}/by-ids`, ids );
  }
}
