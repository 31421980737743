import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DialogModule } from '@angular/cdk/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AssetDetailsComponent } from './components/asset-details/asset-details.component';
import { AssetFamilyDetailsComponent } from './components/asset-management-module/asset-family-details/asset-family-details.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AssetsComponent } from './components/assets/assets.component';
import { SitesComponent } from './components/sites/sites.component';
import { SiteDetailsModalComponent } from './components/_modals/site-details-modal/site-details-modal.component';
import { CardSoftwareLibraryModule } from '../../public/card-software-library/src/public-api';
import { CommonModule } from '@angular/common';
import { AssetsDetailsComponent } from './components/assets-details/assets-details.component';
import { CategoryDetailsModalComponent } from './components/_modals/category-details-modal/category-details-modal.component';
import { TypeDetailsModalComponent } from './components/_modals/type-details-modal/type-details-modal.component';
import { TypeDetailsProcessModalComponent } from './components/_modals/type-details-process-modal/type-details-process-modal.component';
import { ModelDetailsModalComponent } from './components/_modals/model-details-modal/model-details-modal.component';
import { ModelPropertyDetailsModalComponent } from './components/_modals/model-property-details-modal/model-property-details-modal.component';
import { ModelDetailsProcessModalComponent } from './components/_modals/model-details-process-modal/model-details-process-modal.component';
@NgModule({
  declarations: [
    AppComponent,
    AssetDetailsComponent,
    AssetFamilyDetailsComponent,
    AssetsComponent,
    SitesComponent,
    SiteDetailsModalComponent,
    AssetsDetailsComponent,
    CategoryDetailsModalComponent,
    TypeDetailsModalComponent,
    TypeDetailsProcessModalComponent,
    ModelDetailsModalComponent,
    ModelPropertyDetailsModalComponent,
    ModelDetailsProcessModalComponent,
  ],
  imports: [
    AppRoutingModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    DialogModule,
    HttpClientModule,
    CardSoftwareLibraryModule,
    CommonModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
