import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  ComponentRef,
  Type,
} from '@angular/core';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class DomService {
  private childComponentRefs: {
    key: number;
    ref: ComponentRef<unknown>;
  }[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  public appendComponentTo(
    parentId: string,
    child: Type<object>,
    childConfig?: Record<string, unknown>,
  ): number | undefined {
    // Create a component reference from the component
    const childComponentRef = this.componentFactoryResolver
      .resolveComponentFactory(child)
      .create(this.injector);

    // Attach the config to the child (inputs and outputs)
    this.attachConfig(childComponentRef, childConfig);

    const componentRefSlot =
      this.reserveChildComponentRefSlot(childComponentRef);
    // Attach component to the appRef so that it's inside the
    // ng component tree
    this.appRef.attachView(childComponentRef.hostView);

    // Get DOM element from component
    const childDomElem = (
      childComponentRef.hostView as EmbeddedViewRef<unknown>
    ).rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    const parentElement = document.getElementById(parentId);
    if (parentElement) {
      parentElement.appendChild(childDomElem);
    } else {
      console.error(
        `Failed to find an element with ID ${
          parentId
        }. Cannot attach child element to DOM.`,
      );
      this.removeComponent(componentRefSlot);
      return undefined;
    }
    return componentRefSlot;
  }

  public removeComponent(componentRefSlot: number) {
    const childRefInfo = _.find(
      this.childComponentRefs,
      (ref) => ref.key === componentRefSlot,
    );
    if (childRefInfo) {
      const childComponentRef = childRefInfo.ref;
      this.appRef.detachView(childComponentRef.hostView);
      childComponentRef.destroy();
    }
  }

  private reserveChildComponentRefSlot(ref: ComponentRef<unknown>): number {
    const currentLargestSlot = _.max(this.childComponentRefs.map((x) => x.key));
    let newSlot = 0;
    if (currentLargestSlot !== undefined) {
      newSlot = currentLargestSlot + 1;
    }
    this.childComponentRefs.push({ key: newSlot, ref });
    return newSlot;
  }

  private attachConfig(
    componentRef: ComponentRef<object>,
    config?: Record<string, unknown>,
  ) {
    Object.assign(componentRef.instance, config);
  }
}
