import { Component, Injector, Input, OnDestroy } from '@angular/core';
import { ModalReturnData } from '../../_models/modal-return-data';
import { KeyService } from '../../_services/key.service';

@Component({
  selector: 'fiber-modal-component-base',
  template: `<p>Do not directly instantiate BaseModalComponent, please.</p>`,
})
export class BaseModalComponent<T> implements OnDestroy {
  @Input() close!: (returnData: ModalReturnData<T>) => void;
  @Input() dismiss!: () => void;

  public keyService: KeyService;
  constructor(injector: Injector) {
    this.keyService = injector.get(KeyService);
    document.body.addEventListener('keydown', this.onKeyDownRef, false);
  }

  ngOnDestroy() {
    document.body.removeEventListener('keydown', this.onKeyDownRef, false);
  }

  private onKeyDown(event: KeyboardEvent) {
    const keyPressed = this.keyService.normalizeKey(event);
    if (keyPressed === 'Escape') {
      this.dismiss();
    }
  }

  private readonly onKeyDownRef = (event: KeyboardEvent) =>
    this.onKeyDown(event);
}
