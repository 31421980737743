import { Component } from '@angular/core';

@Component({
  selector: 'app-asset-family-details',
  templateUrl: './asset-family-details.component.html',
  styleUrl: './asset-family-details.component.scss'
})
export class AssetFamilyDetailsComponent {

}
