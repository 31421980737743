<app-header
  env="dev testing"
  firstName="Testing"
  lastName="Last Name"
></app-header>

<div>
  <app-nav-menu [menuItems]="menuItems"></app-nav-menu>
  <router-outlet />
</div>
