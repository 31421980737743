import { Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { KeyService } from '../../_services/key.service';

@Component({
  selector: 'floating-selection-list',
  template: `<p>Please don't directly instantiate FloatingSelectionListComponent.</p>`,
  styleUrls: [],
})
export abstract class FloatingSelectionListComponent<T> implements OnInit, OnDestroy {
  @ViewChild('selectionBoxElement', { static: true })
  selectionBoxElement!: ElementRef;

  @Input() controlId?: string;
  @Input() verticalDirection: 'up' | 'down' = 'down';

  public selectionBox = new FormControl('');

  public isLoading = false;
  public showSuggestions = false;

  readonly maxSuggestionCount = 8;

  // Pass the presence/absence of HTML `required` attribute on to
  // inner input for ARIA purposes.
  // I know, the type here is gross. Ask Angular to change how they do
  // present/absent item binding if you want it to change.
  // https://stackoverflow.com/questions/35212475/how-to-change-html-element-readonly-and-required-attribute-in-angular2-typescrip
  inputRequired: '' | null = null;

  public activeSuggestionIndex?: number;
  protected selectedResult = new Subject<
    | {
        entry: T;
        displayText: string;
      }
    | undefined
  >();

  protected keyService: KeyService;
  private keydown$ = new Subject<[key: string | undefined, preventDefault: () => void]>();

  private _suggestionsList: {
    entry: T;
    displayText: string;
  }[] = [];

  constructor(injector: Injector) {
    this.keyService = injector.get(KeyService);
    this.selectedResult.subscribe((result) => {
      if (result) {
        this.selectionBox.setValue(result.displayText ?? '', { emitEvent: false });
      }
    });
  }

  public get suggestionsEnabled(): boolean {
    return this.showSuggestions && this.suggestionsList.length > 0;
  }

  public get suggestionsList(): {
    entry: T;
    displayText: string;
  }[] {
    return this._suggestionsList;
  }

  public set suggestionsList(
    suggestions: {
      entry: T;
      displayText: string;
    }[],
  ) {
    this._suggestionsList = suggestions.slice(0, this.maxSuggestionCount);
  }

  @Input() set required(indicator: '' | null) {
    this.inputRequired = indicator;
  }

  public onKeydown(event: KeyboardEvent) {
    if (this.suggestionsEnabled) {
      const key = this.keyService.normalizeKey(event);
      const preventDefault = () => {
        event.preventDefault();
      };

      this.keydown$.next([key, preventDefault]);
    }
  }

  ngOnInit() {
    this.keydown$.pipe(filter(([key]) => key === 'Escape')).subscribe(([_, preventDefault]) => {
      this.hideSuggestions();
      preventDefault();
    });
    this.keydown$.pipe(filter(([key]) => key === 'Enter' || key === 'Tab')).subscribe(([_, preventDefault]) => {
      this.selectCurrentSuggestion();
      preventDefault();
    });
    this.keydown$.pipe(filter(([key]) => key === 'ArrowUp')).subscribe((_) => {
      this.tryShiftCurrentSuggestion(-1);
    });
    this.keydown$.pipe(filter(([key]) => key === 'ArrowDown')).subscribe((_) => {
      this.tryShiftCurrentSuggestion(1);
    });
  }

  displaySuggestions() {
    this.showSuggestions = true;
  }

  hideSuggestions() {
    this.showSuggestions = false;
  }

  selectCurrentSuggestion() {
    if (this.activeSuggestionIndex != null) {
      this.selectSuggestion(this.activeSuggestionIndex);
    }
  }

  selectSuggestion(index: number) {
    if (index >= 0 && index < this.suggestionsList.length) {
      const result = this.suggestionsList[index];
      this.selectionBox.setValue(result.displayText, { emitEvent: false });
      this.selectedResult.next(result);
      this.hideSuggestions();
    } else {
      console.error(`Error selecting suggestion: Attempted selection of out-of-bounds index ${index}.`);
    }
  }

  tryShiftCurrentSuggestion(shift: number) {
    if (this.suggestionsList.length > 0) {
      this.activeSuggestionIndex ??= 0;
      let requestedNewIndex = this.activeSuggestionIndex + shift;
      if (requestedNewIndex >= this.suggestionsList.length) {
        requestedNewIndex = this.suggestionsList.length - 1;
      }
      if (requestedNewIndex < 0) {
        requestedNewIndex = 0;
      }
      this.activeSuggestionIndex = requestedNewIndex;
    }
  }

  /**
   * Suggestion-list buttons shouldn't have focus. If/when they get it,
   * redirect the focus back onto the input box.
   * Known setup where this matters:
   * 1. Hold down left-click on a suggestion.
   * 2. While holding left-click, drag mouse elsewhere on the screen.
   * 3. Release left-click.
   * 4. Without this function, the component is in an unexpected state
   *    and no longer functions properly.
   */
  redirectFocus() {
    const selectionBoxEl = this.selectionBoxElement.nativeElement;
    if (selectionBoxEl instanceof HTMLElement) {
      selectionBoxEl.focus();
    }
  }

  _handleBlur(event: FocusEvent) {
    if (!(event.relatedTarget instanceof Element && event.relatedTarget.classList.contains('suggestion-item'))) {
      // related target isn't a suggestion element
      this.hideSuggestions();
    }
    this.handleBlur();
  }

  ngOnDestroy() {
    this.keydown$.complete();
    this.selectedResult.complete();
  }

  public abstract handleBlur(): void;
}
