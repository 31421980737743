import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AssetCategory } from '../models/AssetCategory';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryProxyService {
  private readonly baseUrl = environment.proxyUrls.assetApi + '/api/category';
  constructor(private readonly httpClient: HttpClient) {}

  getCategories(): Observable<AssetCategory[]> {
    return this.httpClient.get<AssetCategory[]>(`${this.baseUrl}`);
  }

  upsertCategory(category: AssetCategory): Observable<AssetCategory> {
    return this.httpClient.post<AssetCategory>(`${this.baseUrl}`, category);
  }
}
