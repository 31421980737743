import { Component, Injector, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseModalComponent } from '../../../../../public/card-software-library/src/public-api';
import { AssetType } from '../../../models/AssetType';
import { TypeProxyService } from '../../../proxies/types.proxy.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetCategory } from '../../../models/AssetCategory';

@Component({
  selector: 'app-type-details-modal',
  templateUrl: './type-details-modal.component.html',
  styleUrl: './type-details-modal.component.scss',
})
export class TypeDetailsModalComponent extends BaseModalComponent<AssetType | null> implements OnInit {
  @Input() type: AssetType | null = null;
  @Input() categories: AssetCategory[] = [];

  constructor(
    injector: Injector,
    private readonly typeProxyService: TypeProxyService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.formTypeGroup.patchValue({
      id: this.type?.id || '',
      name: this.type?.name || '',
      description: this.type?.description || '',
      assetCategoryReference: this.type?.assetCategory?.id || '',
      assetProcesses: this.type?.assetProcesses || [],
      createdBy: this.type?.createdBy || '',
      modifiedBy: this.type?.modifiedBy || '',
      modifiedDate: this.type?.modifiedDate || new Date(),
    });
  }

  name: FormControl<string | null> = new FormControl('', [Validators.required]);
  description: FormControl<string | null> = new FormControl('', [Validators.required]);
  assetCategoryReference: FormControl<string | null> = new FormControl('', [Validators.required]);
  assetProcesses: FormControl<[] | null> = new FormControl([]);
  createdBy: FormControl<string | null> = new FormControl('');
  modifiedBy: FormControl<string | null> = new FormControl('');
  modifiedDate: FormControl<Date> = new FormControl(new Date(), {
    nonNullable: true,
  });

  formTypeGroup: FormGroup = new FormGroup({
    name: this.name,
    description: this.description,
    assetCategoryReference: this.assetCategoryReference,
    assetProcesses: this.assetProcesses,
    createdBy: this.createdBy,
    modifiedBy: this.modifiedBy,
    modifiedDate: this.modifiedDate,
  });

  onClose() {
    this.close({ action: 'close', data: null });
  }

  onSave() {
    if (this.formTypeGroup.valid) {
      const currentDate = new Date();
      this.modifiedDate.setValue(currentDate);

      const type: AssetType = {
        id: this.type?.id ?? '',
        name: this.name.value ?? '',
        description: this.description.value ?? '',
        assetCategoryReference: this.assetCategoryReference.value ?? '',
        assetProcesses: this.assetProcesses.value ?? [],
        createdBy: this.createdBy.value ?? '',
        modifiedBy: this.modifiedBy.value ?? '',
        modifiedDate: currentDate,
      };

      this.typeProxyService.upsertType(type).subscribe((type) => {
        this.close({ action: 'save', data: type });
      });
    }
  }
}
