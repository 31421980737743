//Replicate for stage and prod
export const environment = {
  production: false,
  environment: 'dev',
  title: 'Sierra Asset',
  name: 'sierra-asset',
  proxyUrls: {
    assetApi: 'https://asset.api.dev.sierra-erp.com',
  },
};
