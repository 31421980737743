<div class="suggestion-container">
  <input
    #selectionBoxElement
    id="{{ controlId }}"
    [formControl]="selectionBox"
    (blur)="_handleBlur($event)"
    (keydown)="onKeydown($event)"
    autocomplete="off"
    [attr.required]="inputRequired"
  />
  <div class="suggestion-list" [class.up]="verticalDirection === 'up'" [class.down]="verticalDirection === 'down'">
    <div class="loading-i" *ngIf="isLoading"></div>
    <section class="suggestion-results list-group" *ngIf="suggestionsEnabled">
      <button
        type="button"
        class="suggestion-item"
        *ngFor="let result of suggestionsList; let i = index"
        [class.mainAction]="i === activeSuggestionIndex"
        tabindex="-1"
        (click)="selectSuggestion(i)"
        (focus)="redirectFocus()"
      >
        <span>{{ result.displayText }}</span>
      </button>
    </section>
  </div>
</div>
