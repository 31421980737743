import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Site } from '../models/Site';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SiteProxyService {
  private readonly baseUrl = environment.proxyUrls.assetApi + '/api/site';

  constructor(private readonly httpClient: HttpClient) {}

  getSites(): Observable<Site[]> {
    return this.httpClient.get<Site[]>(`${this.baseUrl}/all`);
  }

  upsertSite(site: Site): Observable<Site> {
    return this.httpClient.post<Site>(`${this.baseUrl}`, site);
  }
}
