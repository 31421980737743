<loading-overlay [isLoading]="isLoading > 0"></loading-overlay>
<main>
  <div class="content" *ngIf="asset">
    <h1>{{ asset.assetId ? asset.assetId : 'New Asset' }}</h1>
    <!-- TODO: Uncomment until JWT is ready -->
    <!-- <section>
      <h3 class="line">Audit Data</h3>
      <div class="row mt-3 ml-3 grid gap-14">
        <div>
          <label for="">created By</label>
          <p>{{ asset.createdBy ? asset.createdBy : 'Null' }}</p>
        </div>
        <div>
          <label for="">Modified By</label>
          <p>{{ asset.modifiedBy ? asset.modifiedBy : 'Null' }}</p>
        </div>
        <div>
          <label for="">Modified Date</label>
          <p>
            {{
              asset.modifiedDate
                ? (asset.modifiedDate | date: 'yyyy-MM-dd')
                : 'Null Date'
            }}
          </p>
        </div>
      </div>
    </section> -->
    <section>
      <h3 class="line">Asset Information</h3>
      <form [formGroup]="formAssetGroup" class="mt-0">
        <div class="row">
          <div class="form-box">
            <label for="assetId">Asset ID</label>
            <input type="text" id="assetId" formControlName="assetId" />
          </div>
          <div class="form-box">
            <label for="">Asset Prefix</label>
            <input type="text" id="prefix" formControlName="prefix" />
          </div>
          <div class="form-box">
            <label for="">Asset Description</label>
            <input type="text" id="description" formControlName="description" />
          </div>
        </div>
        <div class="form-box">
          <label for="assetCategoryReference">Category</label>
          <select name="assetCategoryReference" id="assetCategoryReference" formControlName="assetCategoryReference">
            <option value=""></option>
            <option *ngFor="let category of categories" [value]="category.id">
              {{ category.name }}
            </option>
          </select>
        </div>
        <!-- <div class="form-box">
          <label ngif for="assetTypeReference">Type</label>
          <select
            *ngIf="!!filteredTypes.length; else addNewType"
            name="assetTypeReference"
            id="assetTypeReference"
            formControlName="assetTypeReference"
          >
            <option value=""></option>
            <option *ngFor="let type of filteredTypes" [value]="type.id">
              {{ type.name }}
            </option>
          </select>
          <ng-template #addNewType>
            <a id="addNewType" (click)="onAddNewType()">Add New Type</a>
          </ng-template>
        </div> -->

        <div class="form-box">
          <label for="assetTypeReference">Type</label>
          <!-- Only show when no category is selected -->
          <p *ngIf="!assetCategoryReference.value" class="cursor-not-allowed">Add a category first</p>

          <!-- Only show when category is selected -->
          <ng-container *ngIf="assetCategoryReference.value">
            <select
              *ngIf="!!filteredTypes.length; else addNewType"
              name="assetTypeReference"
              id="assetTypeReference"
              formControlName="assetTypeReference"
            >
              <option value=""></option>
              <option *ngFor="let type of filteredTypes" [value]="type.id">
                {{ type.name }}
              </option>
            </select>
            <ng-template #addNewType>
              <a id="addNewType" (click)="onAddNewType()">Add New Type</a>
            </ng-template>
          </ng-container>
        </div>

        <div class="form-box">
          <label for="assetModelReference">Model</label>

          <!-- <p *ngIf="!assetCategoryReference.value" class="cursor-not-allowed">Select a type first</p> -->

          <ng-container *ngIf="!!filteredTypes.length && assetCategoryReference.value && assetTypeReference.value; else addTypeFirst">
            <select
              *ngIf="!!filteredModels.length; else addNewModel"
              name="assetModelReference"
              id="assetModelReference"
              formControlName="assetModelReference"
            >
              <option *ngFor="let model of filteredModels" [value]="model.id">
                {{ model.name }}
              </option>
            </select>
            <ng-template #addNewModel>
              <a id="addNewModal" (click)="onAddNewModel()">Add New Model</a>
            </ng-template>
          </ng-container>
          <ng-template #addTypeFirst>
            <p class="cursor-not-allowed">Add a type first</p>
          </ng-template>
        </div>
        <div class="form-box">
          <label for="assetManufacturerReference"> Manufacturer </label>
          <!-- <input type="text" id="assetManufacturerReference" formControlName="assetManufacturerReference" /> -->
        </div>
      </form>
    </section>
    <section>
      <h3 class="line">Location</h3>
      <div class="form-box">
        <label for="siteCode">Site Code</label>
        <select name="siteCode" id="siteCode" [formControl]="siteCode">
          <option value=""></option>
          <option *ngFor="let site of sites" [value]="site.code">
            {{ site.name }}
          </option>
        </select>
      </div>
    </section>
    <section>
      <h3 class="line">Model Properties</h3>
      <ul *ngIf="filteredPropertyModels?.length; else noProperties" class="flex flex-col gap-2">
        <li
          *ngFor="let property of filteredPropertyModels"
          class="justify-between max-w-fit flex items-center font-medium px-2 py-1 rounded-full border-2"
          [ngClass]="{
            'bg-gray-300 text-gray-500 cursor-not-allowed': !property.setValuesAtAssetLevel,
            'bg-white text-black cursor-pointer': property.setValuesAtAssetLevel,
          }"
          (click)="enableEditing(property)"
        >
          <p>{{ property.name }}: {{ getValues(property.defaultValues) }}</p>
        </li>
      </ul>

      <div *ngIf="editingProperty" class="mt-2">
        <small class="block text-sm font-medium text-gray-700">
          {{ editingProperty.multipleValues ? 'Add Default Values:' : 'Add a Single Default Value:' }}
        </small>
        <div class="flex gap-2 mt-1">
          <input
            type="text"
            [formControl]="newValue"
            placeholder="Enter a value"
            [hidden]="!editingProperty.multipleValues && addValues.length >= 1"
            (keydown.enter)="addValueToDefaultValues()"
            (keydown.escape)="onCancelEdit()"
          />
          <button
            type="button"
            (click)="addValueToDefaultValues()"
            [disabled]="!editingProperty.multipleValues && addValues.length >= 1"
            class="square"
          >
            <span class="material-icons">add</span>
          </button>
        </div>

        <p *ngIf="!editingProperty?.multipleValues && addValues.length >= 1" class="text-red-500 mt-2">
          This property only allows 1 value.
        </p>

        <ul class="mt-2">
          <li
            *ngFor="let value of addValues; let i = index"
            class="py-2 pl-4 flex items-center justify-between bg-gray-100 rounded-md mb-1 w-fit"
          >
            {{ value }}
            <button *ngIf="editingProperty" type="button" (click)="removeValueFromDefaultValues(i)" class="labels mx-4">
              <span class="material-icons">close</span>
            </button>
          </li>
        </ul>

        <div class="flex gap-2 mt-2">
          <button type="button" (click)="onCancelEdit()">Close</button>
        </div>
      </div>

      <ng-template #noProperties>
        <p class="cursor-not-allowed mt-3 text-red-500">No properties found for the selected model.</p>
      </ng-template>
      <ng-template #editProperty>
        <p>Edit Property</p>
      </ng-template>
    </section>
  </div>
  <footer>
    <button class="mainAction" (click)="onSave()" type="submit">Save</button>
    <button (click)="onClose()">Cancel</button>
  </footer>
</main>
