import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetProxyService } from '../../proxies/asset.proxy.service';
import { Asset, AssetModelPropertyWithValues } from '../../models/Asset';
import { FormControl, FormGroup } from '@angular/forms';
import { AssetCategory } from '../../models/AssetCategory';
import { CategoryProxyService } from '../../proxies/category.proxy.service';
import { SiteProxyService } from '../../proxies/site.proxy.service';
import { forkJoin } from 'rxjs';
import { Site } from '../../models/Site';
import { AssetType } from '../../models/AssetType';
import { AssetModel } from '../../models/AssetModel';
import { TypeProxyService } from '../../proxies/types.proxy.service';
import { ModelProxyService } from '../../proxies/model.proxy.service';
import { AssetModelProperty } from '../../models/AssetModelProperty';
import { ModelPropertyProxyService } from '../../proxies/model-property.proxy.service';
import _ from 'lodash';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss'], // Fixing typo: styleUrl -> styleUrls
})
export class AssetDetailsComponent implements OnInit {
  getAssetId!: string;
  asset!: Asset;
  isLoading = 0;

  categories: AssetCategory[] = [];
  types: AssetType[] = [];
  filteredTypes: AssetType[] = [];
  models: AssetModel[] = [];
  filteredModels: AssetModel[] = [];
  sites: Site[] = [];
  propertyModels: AssetModelProperty[] = [];
  filteredPropertyModels: AssetModelProperty[] = [];

  editingProperty: AssetModelProperty | null = null;
  addValues: string[] = [];
  newValue: FormControl<string | null> = new FormControl('');
  disableAddButton: boolean = false;

  @Input() assetData: Asset | null = null;

  currentDate = new Date();
  assetDefault: Asset = {
    id: '',
    assetId: '',
    prefix: '',
    description: '',
    assetCategoryReference: '',
    siteCode: '',
    assetTypeReference: '',
    assetModelReference: '',
    assetManufacturerReference: '',
    assetModelParameters: [],
    createdBy: '',
    modifiedBy: '',
    modifiedDate: new Date(),
  };

  assetId: FormControl<string | null> = new FormControl('');
  prefix: FormControl<string | null> = new FormControl('');
  description: FormControl<string | null> = new FormControl('');
  assetCategoryReference: FormControl<string | null> = new FormControl('');
  siteCode: FormControl<string | null> = new FormControl('');
  assetTypeReference: FormControl<string | null> = new FormControl('');
  assetModelReference: FormControl<string | null> = new FormControl('');
  assetManufacturerReference: FormControl<string | null> = new FormControl('');
  createdBy: FormControl<string | null> = new FormControl('');
  modifiedBy: FormControl<string | null> = new FormControl('');
  modifiedDate: FormControl<Date> = new FormControl(new Date(), {
    nonNullable: true,
  });

  formAssetGroup: FormGroup = new FormGroup({
    assetId: this.assetId,
    prefix: this.prefix,
    description: this.description,
    assetCategoryReference: this.assetCategoryReference,
    siteCode: this.siteCode,
    assetTypeReference: this.assetTypeReference,
    assetModelReference: this.assetModelReference,
    assetManufacturerReference: this.assetManufacturerReference,
    createdBy: this.createdBy,
    modifiedBy: this.modifiedBy,
    modifiedDate: this.modifiedDate,
  });

  constructor(
    private readonly assetProxyService: AssetProxyService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly categoryProxyService: CategoryProxyService,
    private readonly siteProxyService: SiteProxyService,
    private readonly typeProxyService: TypeProxyService,
    private readonly modelProxyService: ModelProxyService,
    private readonly modelPropertyProxyService: ModelPropertyProxyService,
  ) {}

  ngOnInit(): void {
    this.isLoading++;
    forkJoin([
      this.categoryProxyService.getCategories(),
      this.siteProxyService.getSites(),
      this.typeProxyService.getTypes(),
      this.modelProxyService.getModels(),
    ]).subscribe(([categories, sites, types, models]) => {
      this.isLoading--;
      this.categories = categories;
      this.sites = sites;
      this.types = types;
      this.models = models;
      this.getAsset();
    });

    this.assetCategoryReference.valueChanges.subscribe((reference) => {
      this.asset.assetModelParameters = [];

      this.filteredPropertyModels = [];
      this.editingProperty = null;
      if (reference) {
        this.editingProperty = null;
        this.assetTypeReference.setValue('');
        this.assetModelReference.setValue('');
        this.populateTypes();
        this.populateModels();
      }
    });

    this.assetTypeReference.valueChanges.subscribe((reference) => {
      this.asset.assetModelParameters = [];
      this.filteredPropertyModels = [];
      this.editingProperty = null;
      if (reference) {
        this.assetModelReference.setValue('');
        this.populateModels();
      }
    });

    this.assetModelReference.valueChanges.subscribe((modelId) => {
      if (modelId) {
        this.editingProperty = null;
        this.asset.assetModelParameters = [];
        this.populateModelProperties(modelId);
      } else {
        this.filteredPropertyModels = [];
        this.editingProperty = null;
        this.asset.assetModelParameters = [];
      }
    });
  }

  getAsset() {
    this.route.queryParams.subscribe((params) => {
      this.getAssetId = params['assetId'];

      if (this.getAssetId) {
        this.isLoading++;
        this.assetProxyService.getAssetById(this.getAssetId).subscribe((res) => {
          this.asset = res;

          const originalParams = res.assetModelParameters;

          this.formAssetGroup.patchValue({
            assetId: this.asset?.assetId || '',
            prefix: this.asset?.prefix || '',
            description: this.asset?.description || '',
            assetCategoryReference: this.asset?.assetCategoryReference || '',
            siteCode: this.asset?.siteCode || '',
            assetTypeReference: this.asset?.assetTypeReference || '',
            assetModelReference: this.asset?.assetModelReference || '',
            assetManufacturerReference: this.asset?.assetManufacturerReference || '',
            assetModelParameters: this.asset?.assetModelParameters || [],
            createdBy: this.asset?.createdBy || '',
            modifiedBy: this.asset?.modifiedBy || '',
            modifiedDate: this.asset?.modifiedDate || new Date(),
          });
          this.isLoading--;
          this.populateTypes();
          this.populateModels();
          this.asset.assetModelParameters = originalParams;
        });
      } else {
        this.asset = this.assetDefault;
        this.formAssetGroup.patchValue(this.assetDefault);
      }
    });
  }

  populateTypes() {
    this.filteredTypes = this.types.filter((type) => type.assetCategory?.id === this.assetCategoryReference.value);
  }

  populateModels() {
    this.filteredModels = this.models.filter((model) => model.assetType?.id === this.assetTypeReference.value);
  }

  populateModelProperties(modelId: string): void {
    this.isLoading++;

    const selectedModel = this.models.find((model) => model.id === modelId);

    if (selectedModel && (selectedModel.modelProperties?.length ?? 0) > 0) {
      // Llamar al API con los IDs correctos
      this.modelPropertyProxyService
        .getMultipleModelProperties(selectedModel.modelProperties?.map((property) => property.id) ?? [])
        .subscribe(
          (properties) => {
            this.filteredPropertyModels = _.map(properties, (property) => {
              property.defaultValues =
                this.asset?.assetModelParameters.find((param) => param.id === property.id)?.values ?? property.defaultValues;
              return property;
            });
            this.isLoading--;
          },
          (error) => {
            this.filteredPropertyModels = [];
            this.isLoading--;
          },
        );
    } else {
      this.filteredPropertyModels = [];
      this.isLoading--;
    }
  }

  enableEditing(property: AssetModelProperty): void {
    if (property.setValuesAtAssetLevel) {
      this.editingProperty = property;
      this.addValues = property.defaultValues;
    }
  }

  addValueToDefaultValues(): void {
    if (!this.newValue.value) {
      console.warn('Input value is empty');
      return;
    }

    if (this.editingProperty?.multipleValues) {
      this.addValues.push(this.newValue.value.trim());

      this.newValue.setValue('');
    } else {
      if (this.addValues.length >= 1) {
        this.disableAddButton = true;
      } else {
        this.addValues.push(this.newValue.value.trim());
        this.newValue.setValue('');
      }
    }

    this.newValue.setValue('');
  }

  onCancelEdit(): void {
    this.clearEditingState();
  }

  removeValueFromDefaultValues(index: number): void {
    this.addValues.splice(index, 1);
  }

  getValues(values: string[]): string {
    return values.join(', ');
  }

  onSaveValueProperty(property: AssetModelProperty): void {
    this.filteredPropertyModels.map((propertyModel) => {
      if (propertyModel.id === property.id) {
        propertyModel.defaultValues.push(...(this.newValue.value ? [this.newValue.value] : []));
      }
    });
    this.newValue.setValue('');
  }

  clearEditingState(): void {
    this.editingProperty = null;
    this.addValues = [];
    this.newValue.setValue('');
  }

  onClose() {
    this.router.navigate(['/assets']);
  }

  onAddNewType() {
    this.router.navigate(['/admin/assets-details'], {
      queryParams: { addNewType: true, categoryReference: this.assetCategoryReference.value },
    });
  }

  onAddNewModel() {
    this.router.navigate(['/admin/assets-details'], {
      queryParams: { addNewModel: true, typeReference: this.assetTypeReference.value },
    });
  }

  onSave() {
    if (this.formAssetGroup.valid && this.assetId.value) {
      const currentDate = new Date();
      this.modifiedDate.setValue(currentDate);

      const asset: Asset = {
        id: this.asset?.id || '',
        assetId: this.assetId.value || '',
        prefix: this.prefix.value || '',
        description: this.description.value || '',
        assetCategoryReference: this.assetCategoryReference.value || '',
        siteCode: this.siteCode.value || '',
        assetTypeReference: this.assetTypeReference.value || '',
        assetModelReference: this.assetModelReference.value || '',
        assetManufacturerReference: this.assetManufacturerReference.value || '',
        assetModelParameters: this.filteredPropertyModels
          .filter((p) => p.setValuesAtAssetLevel)
          .map((property) => ({
            id: property.id,
            values: property.defaultValues,
          })),
        createdBy: this.createdBy.value || '',
        modifiedBy: this.modifiedBy.value || '',
        modifiedDate: currentDate,
      };

      this.assetProxyService.upsertAsset(asset).subscribe(
        (asset) => {
          console.log('Asset updated:', asset);
          this.router.navigate(['/assets']);
        },
        (error) => {
          console.error('Error:', error);
        },
      );
    }
  }
}
