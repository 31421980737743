<header class="row header">
  <h2>{{ type?.id ? 'Edit Type' : 'Add Type' }}</h2>
  <button (click)="onClose()" class="square">
    <span class="material-icons white text-white">close</span>
  </button>
</header>
<section class="content">
  <form [formGroup]="formTypeGroup">
    <div class="form-box">
      <label for="name" ariaRequired="true">Name</label>
      <input type="text" id="name" formControlName="name" />
    </div>
    <div class="form-box">
      <label for="description" ariaRequired="true">Description</label>
      <input type="text" id="description" formControlName="description" />
    </div>

    <div class="form-box">
      <label for="assetCategoryReference" ariaRequired="true">Category</label>
      <select id="assetCategoryReference" formControlName="assetCategoryReference">
        <option value="">Select Category</option>
        <option *ngFor="let category of categories" [value]="category.id">
          {{ category.name }}
        </option>
      </select>
    </div>

    <!-- Hidden until JWT are ready -->
     <!-- <h3 class="line">History</h3>
    //  <div class="form-box">
    //   <label for="createdBy">Created By</label>
    //   <p>
    //     {{ type?.createdBy }}
    //   </p>
    // </div>
    // <div class="form-box">
    //   <label for="modifiedBy">Modified By</label>
    //   <p>
    //     {{ type?.modifiedBy }}
    //   </p>
    // </div>
    // <div class="form-box">
    //   <label for="modifiedDate"> Modified Date </label>
    //   <p>
    //     {{ modifiedDate.value | date: 'yyyy-MM-dd' }}
    // </div> -->
  </form>
</section>
<footer>
  <button class="mainAction" (click)="onSave()" [disabled]="formTypeGroup.invalid" type="submit">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
