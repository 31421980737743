<div *ngIf="!hideMenu" class="nav-menu-container">
  <ul>
    <li *ngFor="let menuItem of menuItems">
      <span
        (click)="menuItemSelected(menuItem)"
        role="button"
        tabindex="0"
        [ngClass]="{
          selected:
            selectedMenuItem?.routerLink === menuItem.routerLink &&
            !menuItem.children,
        }"
        >{{ menuItem.displayName
        }}<span class="material-icons" *ngIf="!!menuItem?.children"
          >keyboard_arrow_down</span
        ></span
      >
      <ul role="group" *ngIf="!!menuItem?.children">
        <li
          (click)="menuItemSelected(childrenItem)"
          role="button"
          *ngFor="let childrenItem of menuItem.children"
        >
          <a
            [ngClass]="{
              selected:
                selectedMenuItem?.routerLink === childrenItem.routerLink,
            }"
            >{{ childrenItem.displayName }}</a
          >
        </li>
      </ul>
    </li>
  </ul>
</div>
