import { Component, Injector, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { BaseModalComponent } from '../../../../../public/card-software-library/src/public-api';
import { AssetModelProperty } from '../../../models/AssetModelProperty';
import { Form, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AssetType } from '../../../models/AssetType';
import { ModelPropertyProxyService } from '../../../proxies/model-property.proxy.service';

@Component({
  selector: 'app-model-property-details-modal',
  templateUrl: './model-property-details-modal.component.html',
  styleUrl: './model-property-details-modal.component.scss',
})
export class ModelPropertyDetailsModalComponent extends BaseModalComponent<AssetModelProperty | null> implements OnInit {
  @Input() assetModelProperty: AssetModelProperty | null = null;

  types: AssetType[] = [];

  isEditing = false;
  editingIndex: number | null = null;
  editingValue: FormControl<string | null> = new FormControl('', [Validators.required]);
  defaultValues: string[] = [];
  defaultValue: FormControl<string | null> = new FormControl('');

  name: FormControl<string | null> = new FormControl('', [Validators.required]);
  description: FormControl<string | null> = new FormControl('', [Validators.required]);
  setValuesAtAssetLevel: FormControl<boolean | null> = new FormControl(false, [Validators.required]);
  multipleValues: FormControl<boolean | null> = new FormControl(false, [Validators.required]);

  formModelGroup: FormGroup = new FormGroup({
    name: this.name,
    description: this.description,
    setValuesAtAssetLevel: this.setValuesAtAssetLevel,
    multipleValues: this.multipleValues,
  });

  constructor(
    injector: Injector,
    private readonly modelProxyPropertyService: ModelPropertyProxyService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.formModelGroup.patchValue({
      name: this.assetModelProperty?.name || '',
      description: this.assetModelProperty?.description || '',
      setValuesAtAssetLevel: this.assetModelProperty?.setValuesAtAssetLevel || false,
      multipleValues: this.assetModelProperty?.multipleValues || false,
    });
    this.defaultValues = this.assetModelProperty?.defaultValues || [];
    this.defaultValue.setValue(this.assetModelProperty?.multipleValues ? null : this.defaultValues[0]);
  }

  addValue() {
    this.isEditing = true;
    this.editingIndex = null;
    this.editingValue.setValue(null);
  }

  editValue(index: number) {
    this.isEditing = true;
    this.editingIndex = index;
    this.editingValue.setValue(this.defaultValues[index]);
  }

  removeValue(index: number) {
    this.defaultValues.splice(index, 1);
  }

  saveValue() {
    const findValue = this.defaultValues.find((value) => value === this.editingValue.value);
    if (findValue) {
      return;
    }
    if (this.editingValue.value !== null) {
      this.isEditing = false;
      if (this.editingIndex !== null) {
        this.defaultValues[this.editingIndex] = this.editingValue.value;
      } else {
        this.defaultValues.push(this.editingValue.value);
      }
    }
  }

  onClose() {
    this.name.value;
    this.formModelGroup.controls['name'].value;
    this.close({ action: 'close', data: null });
  }

  onSave() {
    if (this.formModelGroup.valid) {
      const currentDate = new Date();

      const modelProperty: AssetModelProperty = {
        id: this.assetModelProperty?.id ?? '',
        name: this.name.value ?? '',
        description: this.description.value ?? '',
        setValuesAtAssetLevel: this.setValuesAtAssetLevel.value ?? false,
        multipleValues: this.multipleValues.value ?? false,
        defaultValues: this.setValuesAtAssetLevel.value
          ? []
          : this.multipleValues.value
            ? this.defaultValues
            : [this.defaultValue.value ?? ''],
      };

      this.modelProxyPropertyService.upsertModel(modelProperty).subscribe({
        next: (response) => {
          this.close({ action: 'save', data: response });
        },
        error: (error) => {
          console.log('Error saving model', error);
        },
      });
    }
  }
}
