import { Injectable, Type } from '@angular/core';
import * as _ from 'lodash';
import { finalize, Observable, Subject } from 'rxjs';
import { DomService } from './dom.service';
import { ModalReturnData } from '../_models/modal-return-data';
import { BaseModalComponent } from '../_components/base-modal/base-modal.component';

interface ModalConfig {
  /**
A space-separated list of CSS classes to apply to the modal.
     */
  modalClass?: string;
  /**
Whether clicking on the veil over the page should dismiss the modal.
     *
@default true
     */
  closeOnVeilClick?: boolean;
  /**
Whether the veil should cover the entire page, rather than just the main
content.
     *
@default false
     */
  fullVeil?: boolean;
  /**
The location on the screen at which the modal should be drawn.
     *
@default 'right'
     */
  position?: 'middle' | 'right';
}

@Injectable({ providedIn: 'root' })
export class ModalService {
  private readonly modalElementIdPrefix: string = 'modal-container-';
  private readonly overlayElementIdPrefix: string = 'modal-veil-';

  private reservedModalSlots = new Set<number>();
  private domServiceSlotMap: Record<number, number> = {};

  constructor(private domService: DomService) {}
  open<T>(
    component: Type<BaseModalComponent<T>>,
    config: ModalConfig,
    inputs: Record<string, unknown>
  ): Observable<ModalReturnData<T>> {
    // set config defaults
    config.closeOnVeilClick ??= true;
    config.fullVeil ??= false;
    config.position ??= 'right';

    const modalContainer = document.createElement('div');
    const modalVeil = document.createElement('div');
    const returnSubject = new Subject<ModalReturnData<T>>();

    const modalSlot = this.reserveNewModalSlot();

    modalContainer.id = this.modalElementIdPrefix + modalSlot.toString();
    modalContainer.className = 'panel modal';

    document.getElementsByTagName('body')[0].appendChild(modalContainer);

    modalVeil.id = this.overlayElementIdPrefix + modalSlot.toString();
    modalVeil.className = 'veil';

    let zIndex = 110 + 2 * modalSlot;

    if (config.fullVeil) {
      modalVeil.style.top = '0px';
      modalVeil.style.height = '100%';
      zIndex += 100;
    }

    modalVeil.style.zIndex = zIndex.toString();
    modalContainer.style.zIndex = (zIndex + 1).toString();

    if (config.position === 'middle') {
      modalContainer.className += ' confirm';
    }

    const closeFn = (returnData: ModalReturnData<T>) =>
      this.returnWith<T>(
        returnData,
        modalSlot,
        modalContainer,
        modalVeil,
        returnSubject
      );

    const dismissFn = () =>
      this.returnWith<T>(
        { action: 'Cancel' },
        modalSlot,
        modalContainer,
        modalVeil,
        returnSubject
      );

    if (config.closeOnVeilClick) {
      modalVeil.onclick = () => {
        dismissFn();
      };
    }

    document.getElementsByTagName('body')[0].appendChild(modalVeil);

    const domServiceId = this.domService.appendComponentTo(
      modalContainer.id,
      component,
      Object.assign(inputs, {
        close: closeFn,
        dismiss: dismissFn,
      })
    );
    if (domServiceId !== undefined) {
      this.domServiceSlotMap[modalSlot] = domServiceId;
    }

    setTimeout(() => {
      this.applyModalClass(modalContainer, config);

      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }

      const autoFocusElements = this.getAllElementsWithAttribute(
        modalContainer,
        'autofocus'
      );

      if (autoFocusElements.length > 0) {
        autoFocusElements[0].focus();
      }
    }, 0);

    return returnSubject.asObservable().pipe(
      finalize(() => {
        dismissFn();
      })
    );
  }

  private getAllElementsWithAttribute(
    rootElement: HTMLElement,
    attribute: string
  ): HTMLElement[] {
    const allElements = rootElement.getElementsByTagName('*');
    return _.filter(
      allElements,
      (element): element is HTMLElement =>
        element instanceof HTMLElement &&
        element.getAttribute(attribute) != null
    );
  }

  private returnWith<T>(
    returnData: ModalReturnData<T>,
    modalSlot: number,
    modalContainer: HTMLElement,
    modalVeil: HTMLElement,
    returnSubject: Subject<ModalReturnData<T>>
  ) {
    if (!returnSubject.closed) {
      returnSubject.next(returnData);
      returnSubject.complete();

      modalContainer.classList.add('destroy');
      modalVeil.classList.add('destroy');
      // Timeout so modal content doesn't disappear before animation
      // is done
      setTimeout(() => {
        this.domService.removeComponent(this.domServiceSlotMap[modalSlot]);
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete this.domServiceSlotMap[modalSlot];
        // can't use plain 'remove' because of IE
        if (modalContainer.parentNode) {
          modalContainer.parentNode.removeChild(modalContainer);
        }
        if (modalVeil.parentNode) {
          modalVeil.parentNode.removeChild(modalVeil);
        }
        this.reservedModalSlots.delete(modalSlot);
      }, 500);
    }
  }

  private reserveNewModalSlot(): number {
    const maxCurrentSlot = _.max(Array.from(this.reservedModalSlots));
    let newSlot = 0;
    if (maxCurrentSlot !== undefined) {
      newSlot = maxCurrentSlot + 1;
    }
    this.reservedModalSlots.add(newSlot);
    return newSlot;
  }

  private applyModalClass(modalHost: HTMLElement, config: ModalConfig) {
    if (config.modalClass) {
      const cssClasses = config.modalClass.split(' ');

      for (const cssClass of cssClasses) {
        modalHost.classList.add(cssClass);
      }
    }
  }
}
