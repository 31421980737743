<header class="row header">
  <h2>{{ assetModelProperty?.id ? 'Edit Model Property' : 'Add Model Property' }}</h2>
  <button (click)="onClose()" class="square">
    <span class="material-icons white text-white">close</span>
  </button>
</header>
<section class="content">
  <form [formGroup]="formModelGroup" class="w-full">
    <div class="form-box">
      <label for="name">Name</label>
      <input type="text" id="name" formControlName="name" />
    </div>
    <div class="form-box">
      <label for="description">Description</label>
      <input type="text" id="description" formControlName="description" />
    </div>
    <div class="form-box">
      <label for="setValuesAtAssetLevel">Set Value At Asset Level</label>
      <input type="checkbox" id="setValuesAtAssetLevel" formControlName="setValuesAtAssetLevel" [value]="setValuesAtAssetLevel.value" />
    </div>
    <div class="form-box">
      <label for="multipleValues">Allow Multiple Values</label>
      <input type="checkbox" id="multipleValues" formControlName="multipleValues" [value]="multipleValues.value" />
    </div>

    <section *ngIf="!setValuesAtAssetLevel.value">
      <h3 class="line">Default Value(s)</h3>
      <div class="flow" *ngIf="multipleValues.value; else singleValue">
        <button (click)="addValue()" class="flat max-w-fit"><span class="material-icons">add</span>Add</button>
        <ul class="flex flex-col gap-2">
          <li
            *ngFor="let value of defaultValues; let i = index"
            class="justify-between max-w-fit flex items-center font-medium px-2 py-1 rounded-full border-2"
          >
            <p (click)="editValue(i)" class="px-2">{{ value }}</p>
            <button (click)="removeValue(i)" class="square"><span class="material-icons">close</span></button>
          </li>
        </ul>

        <div *ngIf="isEditing">
          <input type="editingValue" [formControl]="editingValue" />
          <button (click)="saveValue()">Save</button>
        </div>
      </div>
      <ng-template #singleValue>
        <div class="flow">
          <div class="form-box"><input type="text" id="defaultValue" [formControl]="defaultValue" /></div>
        </div>
      </ng-template>
    </section>

    <!-- Hidden until JWT are ready -->
    <!-- <h3 class="line">History</h3>
    <div class="form-box">
      <label for="createdBy">Created By</label>
      <p>{{ assetModelProperty?.createdBy }}</p>
    </div>
    <div class="form-box">
      <label for="modifiedBy">Modified By</label>
      <p>{{ assetModelProperty?.modifiedBy }}</p>
    </div>
    <div class="form-box">
      <label for="modifiedDate"> Modified Date </label>
      <p>{{ assetModelProperty?.modifiedDate | date: 'yyyy-MM-dd' }}</p>
    </div> -->
  </form>
</section>
<footer>
  <button class="mainAction" (click)="onSave()" type="submit" [disabled]="formModelGroup.invalid">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
