import { Component } from '@angular/core';
import { MenuItem } from '../../public/card-software-library/src/lib/_models/menu-item';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'asset-ui';

  menuItems: MenuItem[] = [
    {
      displayName: 'Assets',
      routerLink: '/assets',
      redirect: true,
    },
    {
      displayName: 'Administration',
      routerLink: '/admin',
      redirect: false,
      children: [
        {
          displayName: 'Asset Details',
          routerLink: '/assets-details',
          redirect: true,
        },
        {
          displayName: 'Sites',
          routerLink: '/sites',
          redirect: true,
        },
      ],
    },
  ];
}
