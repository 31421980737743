import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '../../_models/menu-item';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrl: './nav-menu.component.scss',
})
export class NavMenuComponent implements OnInit {
  @Input() menuItems: MenuItem[] = [];
  hideMenu = false;

  selectedMenuItem: MenuItem | null = null;

  constructor(private router: Router) {}

  findSelectedMenuItem(menuItems: MenuItem[], url: string, parentLink: string = ''): MenuItem | null {
    for (let menuItem of menuItems) {
      const fullLink = parentLink + menuItem.routerLink;
      if (fullLink === url) {
        return menuItem;
      }
      if (menuItem.children) {
        const childItem = this.findSelectedMenuItem(menuItem.children, url, fullLink);
        if (childItem) {
          return childItem;
        }
      }
    }
    return null;
  }

  menuItemSelected(menuItem: MenuItem) {
    const parentMenuItem = this.menuItems.find((mi) => mi.children?.includes(menuItem));
    this.selectedMenuItem = menuItem;
    const link = parentMenuItem ? parentMenuItem.routerLink + menuItem.routerLink : menuItem.routerLink;
    if (menuItem.redirect) {
      this.router.navigate([link]);
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.hideMenu = event.url === '/login';

        //find the selected menu item
        this.selectedMenuItem = this.findSelectedMenuItem(this.menuItems, event.url);
      }
    });
  }
}
