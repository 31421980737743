<div class="tabs {{ orientation }}">
  <ul role="tablist" (click)="showTabs = !showTabs" [class.show]="showTabs">
    <li *ngFor="let tab of tabs; index as index">
      <a
        [id]="tab.id"
        class="nav-link"
        [class.active]="index === currentActiveIndex"
        [class.disabled]="tab.disabled"
        href
        (click)="select(index); $event.preventDefault()"
        role="tab"
        [attr.tabindex]="tab.disabled ? '-1' : undefined"
        [attr.aria-controls]="
          !destroyOnHide || index === currentActiveIndex
            ? tab.id + '-panel'
            : null
        "
        [attr.aria-expanded]="index === currentActiveIndex"
        [attr.aria-selected]="index === currentActiveIndex"
        [attr.aria-disabled]="tab.disabled"
      >
        {{ tab.title }}
        <ng-template
          *ngIf="tab.titleTpl"
          [ngTemplateOutlet]="tab.titleTpl"
        ></ng-template>
      </a>
    </li>
  </ul>
  <ng-container *ngFor="let tab of tabs; index as index">
    <section
      class="tab-pane {{ index === currentActiveIndex ? 'active' : null }}"
      *ngIf="!destroyOnHide || index === currentActiveIndex"
      role="tabpanel"
      [attr.aria-labelledby]="tab.id"
      id="{{ tab.id }}-panel"
      [attr.aria-expanded]="index === currentActiveIndex"
    >
      <ng-template [ngTemplateOutlet]="tab.contentTpl"></ng-template>
    </section>
  </ng-container>
</div>
