import { Component, Injector, Input, OnInit } from '@angular/core';
import { BaseModalComponent } from '../../../../../public/card-software-library/src/public-api';
import { AssetModel } from '../../../models/AssetModel';
import { ModelProxyService } from '../../../proxies/model.proxy.service';
import { AssetProcess } from '../../../models/AssetType';

@Component({
  selector: 'app-model-details-process-modal',
  templateUrl: './model-details-process-modal.component.html',
  styleUrls: ['./model-details-process-modal.component.scss'],
})
export class ModelDetailsProcessModalComponent extends BaseModalComponent<AssetModel | null> implements OnInit {
  @Input() model: AssetModel | null = null;

  excludedProcessIds: string[] = [];
  originalExcludedProcessIds: string[] = [];
  showProcessesDict: Record<string, boolean> = {};
  saving = false;

  constructor(
    injector: Injector,
    private readonly modelProxyService: ModelProxyService,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    if (!this.model?.assetType) {
      this.onClose();
    }
    this.excludedProcessIds = [...(this.model?.excludedProcessIds || [])];
    this.originalExcludedProcessIds = [...this.excludedProcessIds];
    this.model?.assetType?.assetProcesses.forEach((process) => {
      this.populateIsSelected(process);
    });
  }

  populateIsSelected(assetProcess: AssetProcess): void {
    // Default to selected = true
    assetProcess.selected = true;

    // If the process ID is in the excludedProcessIds, set selected = false
    if (this.isProcessExcluded(assetProcess.id)) {
      assetProcess.selected = false;
    }

    // Recursively apply the logic to all sub-processes
    assetProcess.subProcesses.forEach((subProcess) => {
      this.populateIsSelected(subProcess);
    });
  }

  isProcessExcluded(processId: string): boolean {
    return this.excludedProcessIds.includes(processId);
  }

  toggleProcess(id: string) {
    this.showProcessesDict[id] = !this.showProcessesDict[id];
  }

  selectProcess(processId: string) {
    const process = this.findProcessById(this.model?.assetType?.assetProcesses, processId);
    if (!process) return;

    // Determine the new state of selection
    const isSelected = !process.selected; // Toggle the selected state
    process.selected = isSelected; // Update the process selection state

    // Update child and parent processes
    this.toggleProcessSelection(process, isSelected);
    this.updateParentSelection(process);
  }

  toggleProcessSelection(process: AssetProcess, isSelected: boolean) {
    // Update the `selected` property of the process
    process.selected = isSelected;

    if (isSelected) {
      // Remove the process ID from the excluded list
      this.excludedProcessIds = this.excludedProcessIds.filter((id) => id !== process.id);
    } else {
      // Add the process ID to the excluded list if not already present
      if (!this.excludedProcessIds.includes(process.id)) {
        this.excludedProcessIds.push(process.id);
      }
    }

    // Recursively toggle child processes
    process.subProcesses.forEach((subProcess) => {
      this.toggleProcessSelection(subProcess, isSelected);
    });
  }

  updateParentSelection(process: AssetProcess) {
    // Recursively check parent process selection
    const parent = this.findParentProcess(this.model?.assetType?.assetProcesses, process);
    if (parent) {
      const allSubProcessesSelected = parent.subProcesses.every((sub) => !this.isProcessExcluded(sub.id));
      if (allSubProcessesSelected) {
        this.excludedProcessIds = this.excludedProcessIds.filter((id) => id !== parent.id);
      } else {
        if (!this.excludedProcessIds.includes(parent.id)) {
          this.excludedProcessIds.push(parent.id);
        }
      }
      this.updateParentSelection(parent);
    }
  }

  findProcessById(processes: AssetProcess[] | undefined, processId: string): AssetProcess | null {
    if (!processes) return null;
    for (const process of processes) {
      if (process.id === processId) return process;
      const found = this.findProcessById(process.subProcesses, processId);
      if (found) return found;
    }
    return null;
  }

  findParentProcess(processes: AssetProcess[] | undefined, childProcess: AssetProcess): AssetProcess | null {
    if (!processes) return null;
    for (const process of processes) {
      if (process.subProcesses.includes(childProcess)) return process;
      const parent = this.findParentProcess(process.subProcesses, childProcess);
      if (parent) return parent;
    }
    return null;
  }

  onSave() {
    if (this.saving) return;
    this.saving = true;

    if (!this.model) {
      console.error('Model is null or undefined');
      this.saving = false;
      return;
    }

    this.model.excludedProcessIds = [...this.excludedProcessIds];
    this.model.assetTypeReference = this.model.assetType!.id;
    this.model.modelPropertiesReferences = this.model!.modelProperties!.map((property) => property.id);

    this.modelProxyService.upsertModel(this.model).subscribe(
      () => {
        console.log('Model saved successfully');
        this.saving = false;
        this.onClose();
      },
      (error) => {
        console.error('Error saving model:', error);
        alert('An error occurred while saving the model.');
        this.saving = false;
      },
    );
  }

  onClose() {
    this.excludedProcessIds = [...this.originalExcludedProcessIds];
    this.close({ action: 'close', data: null });
  }

  closeAll() {
    Object.keys(this.showProcessesDict).forEach((key) => {
      this.showProcessesDict[key] = false;
    });
  }

  openAll() {
    Object.keys(this.showProcessesDict).forEach((key) => {
      this.showProcessesDict[key] = true;
    });
  }
}