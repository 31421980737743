import {
  Component,
  Input,
  ContentChildren,
  QueryList,
  TemplateRef,
  Output,
  EventEmitter,
  ContentChild,
  AfterContentInit,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

/**
 * A directive representing an individual tab.
 */
@Component({
  selector: 'cs-tab',
  template: ``,
})
export class TabComponent {
  private static nextId = 0;
  /**
   * Unique tab identifier. Must be unique for the entire document for proper
   * accessibility support.
   */
  @Input() id = `cs-tab-${TabComponent.getNewId()}`;

  /**
   * Simple (string only) title. Use the `#csTabTitle` ng-template for
   * more complex use cases.
   */
  @Input() title?: string;

  /**
   * Allows setting disabled state. Disabled tabs can't be selected.
   */
  @Input() disabled = false;

  @Input() name?: string;

  @ContentChild('csTabTitle') titleTpl?: TemplateRef<unknown>;
  @ContentChild('csTabContent') contentTpl!: TemplateRef<unknown>;
  static getNewId(): number {
    const idToIssue = this.nextId;
    this.nextId++;
    return idToIssue;
  }
}

/**
 * A component that makes it easy to create tabbed interface.
 */
@Component({
  selector: 'cs-tabset',
  styleUrls: ['./tabset.component.scss'],
  templateUrl: './tabset.component.html',
})
export class TabSetComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  @Output() activeIndexChange = new EventEmitter<number | undefined>();
  @Output() activeTabNameChange = new EventEmitter<string | undefined>();

  /**
   * Whether the closed tabs should be hidden without destroying them
   */
  @Input() destroyOnHide = true;

  /**
   * The orientation of the nav (horizontal or vertical).
   */
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';

  /**
   * Type of navigation to be used for tabs. Can be one of Bootstrap defined
   * types ('tabs' or 'pills').
   * Since 3.0.0 can also be an arbitrary string (for custom themes).
   */
  @Input() type: 'tabs' | 'pills' | string = 'tabs';

  showTabs = false;

  /**
   * The index of the currently selected tab.
   */
  currentActiveIndex?: number;

  private preContentInitIndex?: number;
  private preContentInitName?: string;
  private afterContentInit = false;

  private subscriptions: Subscription[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  @Input() set activeIndex(index: number | undefined) {
    if (!this.afterContentInit) {
      this.preContentInitIndex = index;
    } else {
      this.select(index);
    }
  }

  @Input() set activeTabName(name: string | undefined) {
    if (!this.afterContentInit) {
      this.preContentInitName = name;
    } else {
      this.selectByName(name);
    }
  }

  /**
   * Selects the tab with the given id and shows its associated pane.
   * Any other tab that was previously selected becomes unselected and its
   * associated pane is hidden.
   */
  select(tabIndex: number | undefined) {
    if (tabIndex === undefined) {
      this.currentActiveIndex = undefined;
      this.activeIndexChange.emit(undefined);
      this.activeTabNameChange.emit(undefined);
      return;
    }
    const tabsArr = this.tabs.toArray();
    if (tabIndex >= 0 && tabIndex < tabsArr.length) {
      const selectedTab = tabsArr[tabIndex];

      if (!selectedTab.disabled && this.currentActiveIndex !== tabIndex) {
        this.currentActiveIndex = tabIndex;
        this.cdr.detectChanges();
        this.activeIndexChange.emit(this.currentActiveIndex);
        this.activeTabNameChange.emit(selectedTab.name);
      }
    }
  }

  selectByName(tabName: string | undefined) {
    const selectedIndex = _.findIndex(
      this.tabs.toArray(),
      (tab) => tab.name === tabName
    );
    this.select(selectedIndex !== -1 ? selectedIndex : undefined);
  }

  ngAfterContentInit() {
    if (this.preContentInitName != null) {
      this.selectByName(this.preContentInitName);
    } else if (this.preContentInitIndex != null) {
      this.select(this.preContentInitIndex);
    } else {
      this.selectFirstEnabledTab();
    }
    this.afterContentInit = true;
    this.subscriptions.push(
      this.tabs.changes.subscribe(() => {
        if (this.tabs.length === 0) {
          this.select(undefined);
          return;
        }
        if (
          this.activeIndex === undefined ||
          this.tabs.length >= this.activeIndex
        ) {
          this.selectFirstEnabledTab();
          return;
        }
      })
    );
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private selectFirstEnabledTab() {
    const firstEnabledTabIndex = _.findIndex(
      this.tabs.toArray(),
      (tab) => !tab.disabled
    );
    this.select(firstEnabledTabIndex !== -1 ? firstEnabledTabIndex : undefined);
  }
}
