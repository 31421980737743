import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BaseModalComponent } from './_components/base-modal/base-modal.component';
import { NavMenuComponent } from './_components/nav-menu/nav-menu.component';
import { HeaderComponent } from './_components/header/header.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { KeyService } from './_services/key.service';
import { ModalService } from './_services/modal.service';
import { TabComponent, TabSetComponent } from './_components/tabset/tabset.component';
import { LoadingOverlayComponent } from './_components/loading-overlay/loading-overlay.component';
import { CSTypeaheadComponent } from './_components/typeahead/typeahead.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BaseModalComponent,
    NavMenuComponent,
    HeaderComponent,
    TabSetComponent,
    TabComponent,
    LoadingOverlayComponent,
    CSTypeaheadComponent,
  ],
  imports: [BrowserModule, CommonModule, DragDropModule, ReactiveFormsModule],
  providers: [],
  bootstrap: [],
  exports: [
    NavMenuComponent,
    HeaderComponent,
    DragDropModule,
    TabSetComponent,
    TabComponent,
    LoadingOverlayComponent,
    CSTypeaheadComponent,
  ],
})
export class CardSoftwareLibraryModule {
  static forRoot() {
    return {
      ngModule: CardSoftwareLibraryModule,
      providers: [],
    };
  }
}

export {
  BaseModalComponent,
  NavMenuComponent,
  HeaderComponent,
  KeyService,
  ModalService,
  TabSetComponent,
  TabComponent,
  LoadingOverlayComponent,
  CSTypeaheadComponent,
};
