<loading-overlay [isLoading]="isLoading > 0"></loading-overlay>
<main>
  <div class="content">
    <cs-tabset [activeIndex]="activeIndex">
      <cs-tab title="Categories">
        <ng-template #csTabContent>
          <div class="flow local-max-width">
            <div aria-label="Toolbar" class="tool-bar">
              <button class="flat" (click)="openCategoryDetailsModal()">
                <span class="material-icons">add</span>
                Add
              </button>
              <div class="row">
                <label for="">Search</label>
                <input #searchInput type="text" (input)="searchCategory(searchInput.value)" />
              </div>
            </div>
            <section class="section-details">
              <table class="w-full">
                <tbody>
                  <tr *ngFor="let category of filteredCategories" class="flex justify-between">
                    <td class="ml-4 w-full">
                      <div class="row justify-between">
                        <div>
                          <p>{{ category.name }}</p>
                        </div>
                        <button class="square" (click)="openCategoryDetailsModal(category)">
                          <span class="material-icons">edit</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </ng-template>
      </cs-tab>
      <cs-tab title="Types">
        <ng-template #csTabContent>
          <div class="flow local-max-width">
            <div aria-label="Toolbar" class="tool-bar">
              <button class="flat" (click)="openTypeDetailsModal()"><span class="material-icons">add</span> Add</button>
              <div class="row">
                <label for="">Search</label>
                <input #searchInput type="text" (input)="searchType(searchInput.value)" />
              </div>
            </div>
            <section class="section-details">
              <table class="w-full">
                <tbody>
                  <tr *ngFor="let type of filteredTypes" class="flex justify-between">
                    <td class="ml-4 w-full">
                      <div class="row justify-between">
                        <div>
                          <p>{{ type.name }}</p>
                        </div>
                        <div>
                          <button class="square" (click)="openTypeDetailsModal(type)">
                            <span class="material-icons">edit</span>
                          </button>
                          <button class="square" (click)="openTypeDetailsProcessModal(type)">
                            <span class="material-icons">build</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </ng-template>
      </cs-tab>
      <cs-tab title="Models">
        <ng-template #csTabContent>
          <div class="flow local-max-width">
            <div aria-label="Toolbar" class="tool-bar">
              <button class="flat" (click)="openModelDetailsModal()">
                <span class="material-icons">add</span>
                Add
              </button>
              <div class="row">
                <label for="">Search</label>
                <input #searchInput type="text" (input)="searchModel(searchInput.value)" />
              </div>
            </div>
            <section class="section-details">
              <table class="w-full">
                <tbody>
                  <tr *ngFor="let model of filteredModels" class="flex justify-between">
                    <td class="ml-4 w-full">
                      <div class="row justify-between">
                        <div>
                          <p>{{ model.name }}</p>
                        </div>
                        <div>
                          <button class="square" (click)="openModelDetailsModal(model)">
                            <span class="material-icons">edit</span>
                          </button>
                          <button class="square" (click)="openModelDetailsProcessModal(model)">
                            <span class="material-icons">build</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </ng-template>
      </cs-tab>
      <cs-tab title="Model Properties">
        <ng-template #csTabContent>
          <div class="flow local-max-width">
            <div aria-label="Toolbar" class="tool-bar">
              <button class="flat" (click)="openModelPropertiesModal()">
                <span class="material-icons">add</span>
                Add
              </button>
              <div class="row">
                <label for="">Search</label>
                <input #searchInput type="text" (input)="searchModelProperty(searchInput.value)" />
              </div>
            </div>
            <section class="section-details">
              <table class="w-full">
                <tbody>
                  <tr *ngFor="let model of filteredModelProperties" class="flex justify-between">
                    <td class="ml-4 w-full">
                      <div class="row justify-between">
                        <div>
                          <p>{{ model.name }}</p>
                        </div>
                        <button class="square" (click)="openModelPropertiesModal(model)">
                          <span class="material-icons">edit</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        </ng-template>
      </cs-tab>
    </cs-tabset>
  </div>
</main>
