<header class="row header">
  <h2>{{ model?.id ? 'Edit Model' : 'Add Model' }}</h2>
  <button (click)="onClose()" class="square">
    <span class="material-icons white text-white">close</span>
  </button>
</header>
<section class="content">
  <form [formGroup]="formModelGroup">
    <div class="form-box">
      <label for="name" ariaRequired="true">Name</label>
      <input type="text" id="name" formControlName="name" />
    </div>
    <div class="form-box">
      <label for="description" ariaRequired="true">Description</label>
      <input type="text" id="description" formControlName="description" />
    </div>
    <div class="form-box">
      <label for="assetType" ariaRequired="true">Type</label>
      <cs-typeahead
        fieldToDisplay="name"
        [options]="typesTypeahead"
        [formControl]="assetType"
        controlId="assetType"
        selectOnMatch="caseInsensitive"
      ></cs-typeahead>
    </div>
    <div class="form-box">
      <label for="propertiesReference">Model Property</label>
      <cs-typeahead
        fieldToDisplay="name"
        [options]="modelPropertyTypeahead"
        (selected)="onPropertySelected($event)" 
        selectOnMatch="caseInsensitive"
      ></cs-typeahead>
    </div>

    <ul class="flex flex-col gap-2">
      <li
        *ngFor="let property of selectedModelProerties"
        class="justify-between max-w-fit flex items-center font-medium px-2 py-1 rounded-full border-2"
      >
        <p class="px-2">{{ property.name }}</p>
        <button (click)="removeProperty(property)" class="labels"><span class="material-icons">close</span></button>
      </li>
    </ul>

    <!-- Hidden until JWT are ready -->
    <!-- <h3 class="line">History</h3>
    <div class="form-box">
      <label for="createdBy">Created By</label>
      <p>{{ model?.createdBy }}</p>
    </div>
    <div class="form-box">
      <label for="modifiedBy">Modified By</label>
      <p>{{ model?.modifiedBy }}</p>
    </div>
    <div class="form-box">
      <label for="modifiedDate"> Modified Date </label>
      <p>{{ modifiedDate.value | date: 'yyyy-MM-dd' }}</p>
    </div> -->
  </form>
</section>
<footer>
  <button class="mainAction" (click)="onSave()" [disabled]="formModelGroup.invalid" type="submit">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
