<header class="row header">
  <h2>{{ site?.code ? 'Edit Site' : 'Add Site' }}</h2>
  <button (click)="onClose()" class="square">
    <span class="material-icons white text-white">close</span>
  </button>
</header>
<section class="content">
  <form [formGroup]="fromGroup">
    <div class="form-box">
      <label for="code">Site Code</label>
      <input type="text" id="code" formControlName="code" />
    </div>
    <div class="form-box">
      <label for="name">Site Name</label>
      <input type="text" id="name" formControlName="name" />
    </div>
    <div class="form-box">
      <label for="description">Description</label>
      <textarea id="description" formControlName="description"></textarea>
    </div>
    <div class="form-box">
      <label for="countryId">Country ID</label>
      <input type="text" id="countryId" formControlName="countryId" />
    </div>
  </form>
</section>
<footer>
  <button class="mainAction" (click)="onSave()" type="submit">Save</button>
  <button (click)="onClose()">Cancel</button>
</footer>
