<main>
  <loading-overlay [isLoading]="isLoading > 0"></loading-overlay>
  <div class="content">
    <h1 class="line">Sites</h1>
    <div aria-label="Toolbar">
      <button class="flat" (click)="openSiteDetailsModal()">
        <span class="material-icons">add</span>
        Add
      </button>
    </div>

    <div class="body filtered-list">
      <div class="list">
        <table class="w-[80%]">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Description</th>
              <th>Activity</th>
              <th>External Site</th>
              <th>Country ID</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let site of sites">
              <td>
                <a (click)="openSiteDetailsModal(site)">{{ site.code }}</a>
              </td>
              <td>{{ site.name }}</td>
              <td>{{ site.description }}</td>
              <td>{{ site.isActive }}</td>
              <td>{{ site.externalSite }}</td>
              <td>{{ site.countryId }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</main>
