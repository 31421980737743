import { Component, Input, Injector, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { KeysWithType } from '../../_models/internal/members-with-type';
import { FloatingSelectionListComponent } from '../floating-selection-list/floating-selection-list.component';

@Component({
  selector: 'cs-typeahead',
  styleUrls: ['../floating-selection-list/floating-selection-list.component.scss'],
  templateUrl: '../floating-selection-list/floating-selection-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CSTypeaheadComponent,
    },
  ],
})
export class CSTypeaheadComponent<T> extends FloatingSelectionListComponent<T> implements ControlValueAccessor {
  @Input() debounce = 300;
  @Input() selectOnMatch: 'exact' | 'caseInsensitive' | 'disabled' = 'disabled';
  @Input() toDisplayText?: (entry: T) => string;
  @Input() fieldToDisplay?: KeysWithType<T, string>;
  @Input() clearIfUnselected = false;
  @Output() selected: EventEmitter<T> = new EventEmitter<T>();

  constructor(injector: Injector) {
    super(injector);
    this.selectionBox.valueChanges
      .pipe(
        // Run handleTextChanges() before anything else, leaving query
        // unchanged. Would have used multiple subscriptions to a
        // Subject instead, but that leaves the potential for a scary
        // race condition.
        tap(() => {
          this.handleTextChange();
        }),
        debounceTime(this.debounce),
        switchMap((query) => this.suggest(query ?? '')),
      )
      .subscribe((typeaheadResults) => {
        this.buildTypeaheadDisplay(typeaheadResults);
        this.isLoading = false;
      });

    // this.selectedResult.subscribe((result) => {
    //   this.onChangeCallback(result?.entry);
    //   this.hasDefinedValue = Boolean(result?.entry);
    // });
    this.selectedResult.subscribe((result) => {
      if (result?.entry){
        this.onChangeCallback(result.entry);
        this.hasDefinedValue = true;

        this.selected.emit(result.entry);
      }
    });
  }

  @Input() options: (text: string) => Observable<T[]> = (_text) => of([]);

  private hasDefinedValue = false;

  buildTypeaheadDisplay(results: T[]) {
    this.suggestionsList = _.map(results, (result) => ({
      entry: result,
      displayText: this.getDisplayText(result),
    }));

    if (this.suggestionsList.length > 0) {
      this.activeSuggestionIndex = 0;
    } else {
      this.activeSuggestionIndex = undefined;
    }

    const currentText = this.selectionBox.value as string;
    if (currentText != null) {
      if (this.selectOnMatch === 'exact') {
        const matchedIndex = _.findIndex(this.suggestionsList, (record) => record.displayText === currentText);
        if (matchedIndex >= 0) {
          this.selectSuggestion(matchedIndex);
          return;
        }
      }

      if (this.selectOnMatch === 'caseInsensitive') {
        const matchedIndex = _.findIndex(this.suggestionsList, (record) => record.displayText.toUpperCase() === currentText.toUpperCase());

        if (matchedIndex >= 0) {
          this.selectSuggestion(matchedIndex);
          return;
        }
      }
    }

    this.displaySuggestions();
  }

  getDisplayText(entry?: T): string {
    let displayText: string | undefined;

    if (entry != null) {
      if (this.toDisplayText) {
        const fetchedText = this.toDisplayText(entry);
        if (typeof fetchedText === 'string') {
          displayText = fetchedText;
        } else {
          console.error('toDisplayText lookup failed. Please ensure it always returns a string.');
        }
      } else if (this.fieldToDisplay) {
        const selection = entry[this.fieldToDisplay];
        if (typeof selection === 'string') {
          displayText = selection;
        } else {
          console.error('fieldToDisplay lookup failed. Please ensure the field name is typed correctly.');
        }
      }
    }
    displayText ??= entry as unknown as string;

    return displayText ?? '';
  }

  handleTextChange() {
    this.onChangeCallback(undefined);
    this.hasDefinedValue = false;
  }

  suggest(query: string): Observable<T[]> {
    if (query.length > 0) {
      this.isLoading = true;
      return this.options(query);
    } else {
      return of([]);
    }
  }

  handleBlur() {
    if (this.clearIfUnselected && this.selectionBox.dirty && !this.hasDefinedValue) {
      this.selectionBox.setValue('', { emitEvent: false });
    }
    this.onTouchedCallback();
  }

  writeValue(selection: T): void {
    const displayText = this.getDisplayText(selection);
    this.selectionBox.setValue(displayText, { emitEvent: false });
  }

  onChangeCallback = (_entry: unknown) => {
    // filled in by Angular with registerOnChange()
  };
  onTouchedCallback = () => {
    // filled in by Angular with registerOnTouched()
  };

  registerOnChange(fn: (_entry: unknown) => void): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.selectionBox.disable({ emitEvent: false });
    } else {
      this.selectionBox.enable({ emitEvent: false });
    }
  }
}
